/* eslint-disable camelcase */
import isEmpty from 'lodash/isEmpty';
import { LoginResponse, UserProfile, UserProfilePic } from '../../survey-tool/declarations/auth-api';
import { queryObjToString } from '../../utils/uri';
import { ApiCall, ApiCallConfig, getRampartToken, isClientErr } from './api-call';
import { ReferralsPostDto } from '../../declarations/interfaces/dtos/post/ReferralsPostDto';
import { ReferralsResultDto } from '../../declarations/interfaces/dtos/result/ReferralsResultDto';
import {
  ActiveReferralsDto,
  PendingInvitesDto,
  ReferralsStatsDto,
} from '../../declarations/interfaces/dtos/result/getReferralsDtos';
import { BladesDto } from '../../declarations/interfaces/dtos/result/getBladesDto';
import { MobileVerificationResultDto } from '../../declarations/interfaces/dtos/result/MobileVerificationResultDto';
import { eventOnAppLoad } from '../../utils/app-load';
import { getConfigPlatformUuid } from '../../utils/app-config';
import { clearOnboardingData } from '../../onboarding/onboarding-data';
import { _goToAfterLogoutUrl } from '../../utils/login-logout-uri';
import { clearToken } from '../../auth-token';
import { authApiHost } from '../../app-config';
import { UserProfilePostDto } from '../../declarations/interfaces/dtos/post/UserProfilePostDto';
import { logWarn } from '../../utils/log';

export async function AuthApiCall<T>(cfg: ApiCallConfig) {
  return ApiCall<T>(cfg);
}

export interface DomainInfo {
  web_app_login?: boolean;
  login_page_url?: string;
  logout_page_url?: string;
  maintenance_window_enabled?: boolean;
  maintenance_page_url?: string;
  panels: DomainInfoPanel[];
}

export interface DomainInfoPanel {
  code: string;
  uuid: string;
  name: string;
  country: {
    country_code: string;
    country_name: string;
  };
  languages: DomainInfoPanelLanguage[];
  ahApiDomain: string;
}

export interface DomainInfoPanelLanguage {
  language_code: string;
  language_name: string;
  is_default: boolean;
}

export async function getDomainInfo(domain: string) {
  const result = await AuthApiCall<DomainInfo>({
    method: 'GET',
    url: `${authApiHost}/api/v1/public/domain-info?domain=${encodeURIComponent(domain)}`,
  });
  return result.data;
}

export interface AhApiInfo {
  ahApiHost: string;
}

const _getAhApiInfo: Record<string, Promise<AhApiInfo> | undefined> = {};

export async function getAhApiInfo(query: { instanceUuid: string } | { panelUuid: string }) {
  const key = JSON.stringify(query);
  if (!_getAhApiInfo[key]) {
    _getAhApiInfo[key] = AuthApiCall<AhApiInfo>({
      method: 'GET',
      url: `${authApiHost}/api/v1/public/ah-api-info`,
      query,
    })
      .then((result) => result.data)
      .catch((err) => {
        delete _getAhApiInfo[key];
        throw err;
      });
  }
  return _getAhApiInfo[key]!;
}

export async function getRecaptchaRequired(page: 'login' | 'register') {
  const result = await AuthApiCall<{ required: boolean }>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/${page}/recaptcha-required`,
  });
  return result.data;
}

// returns null in case of invalid token
export async function getAuthApiPing() {
  const result = await AuthApiCall<LoginResponse | null>({
    method: 'GET',
    url: `${authApiHost}/api/v1/ping`,
  });
  return result.data;
}

interface SignupArgs {
  panelKey: string;
  email: string;
  password: string;
  acceptedTermsConditions: boolean;
  marketingEmailsChecked?: boolean;
  marketingEmailsText?: string;
  marketingSmsChecked?: boolean;
  marketingSmsText?: string;
  recaptchaKey: string;
  recaptchaResponse: string | null;
  languageCode: string;
  source: string | undefined;
  referrer: string;
}

export interface OnboardingResponse {
  onboarding: {
    panelUuid: string;
    provider: string;
    userId: string;
  };
}

export const parentFrameUrl = window.location !== window.parent.location ? document.referrer : document.location.href;

export async function callUserRegister(args: SignupArgs) {
  const result = await AuthApiCall<LoginResponse | OnboardingResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v2/user/register`,
    data: {
      parentFrameUrl,
      ...args,
    },
  });
  return result.data;
}

interface LoginArgs {
  panelKey: string;
  email: string;
  password: string;
  recaptchaKey: string;
  recaptchaResponse: string | null;
}

export async function callUserLogin(args: LoginArgs) {
  const result = await AuthApiCall<LoginResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v2/user/login`,
    data: {
      parentFrameUrl,
      ...args,
    },
  });
  return result.data;
}

interface ResetPasswordArgs {
  token: string;
  password: string;
}
export async function resetPassword(args: ResetPasswordArgs) {
  const result = await AuthApiCall<LoginResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/reset-password`,
    data: {
      ...args,
    },
  });
  return result.data;
}

export async function logout(): Promise<void> {
  try {
    clearOnboardingData();
  } catch (err) {
    // no-op
  }
  const result = await AuthApiCall({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/logout`,
  });
  const data = result.data as { redirect?: string };
  // this method is void, doesn't return anything
  // once you logout, you should leave the app
  // 1) clear any tokens from cookies
  clearToken();
  // 2) redirect to /logout page (do not use history.push, servers must be able to catch this)
  if (data?.redirect) {
    return _goToAfterLogoutUrl(data.redirect);
  }
  const isLogout = window.location.pathname.startsWith('/logout');
  // check needs to be here to prevent infinite loop
  if (!isLogout) {
    // we need to redirect to logout page since news connect app is catching this url!
    let redirect;
    if (!['/login', '/logout', '/error'].includes(window.location.pathname)) {
      redirect = window.location.href;
    }

    let url = '/logout';
    if (redirect) {
      url += `?redirect=${encodeURIComponent(redirect)}`;
    }
    window.location.href = url;
  }
}

export enum OAuthProvider {
  GOOGLE = 'google',
  TWITTER = 'twitter',
  FACEBOOK = 'facebook',
}

export async function socialConnect(provider: OAuthProvider, socialToken: string, secret?: string) {
  const data: any = {
    token: socialToken,
    platformKey: getConfigPlatformUuid(),
  };

  if (secret) {
    data.secret = secret;
  }

  const result = await AuthApiCall<LoginResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v1/oauth/${provider}/id-token/connect`,
    data,
    doNotRemoveAuthCookie: true,
  });
  return result.data;
}

export async function socialDisconnect(provider: OAuthProvider) {
  const result = await AuthApiCall<LoginResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v1/oauth/disconnect`,
    data: {
      provider,
    },
    doNotRemoveAuthCookie: true,
  });
  return result.data;
}

export async function getProfile(queryObj?: Record<string, string>): Promise<UserProfile> {
  let url = `${authApiHost}/api/v1/user/profile`;
  if (queryObj) {
    url += `?${queryObjToString(queryObj)}`;
  }
  const result = await AuthApiCall<UserProfile>({
    method: 'GET',
    url,
    headers: { 'rampart-token': getRampartToken()! },
  });
  return eventOnAppLoad(result.data);
}

export async function trackBrowserDetails(data: any): Promise<UserProfile> {
  const url = `${authApiHost}/api/v1/track/browser-details`;
  const result = await AuthApiCall<UserProfile>({
    method: 'POST',
    url,
    data,
    headers: { 'rampart-token': getRampartToken()! },
  });
  return result.data;
}

export async function saveProfile(userProfile: UserProfile, payload: UserProfilePostDto) {
  for (const key of Object.keys(payload)) {
    if ((payload as any)[key] === (userProfile as any)[key]) {
      logWarn(
        `saveProfile: property ${key}=${(payload as any)[key]} is identical to the current state, removing from payload`
      );
      delete (payload as any)[key];
    }
  }
  if (isEmpty(payload)) {
    throw new Error(`saveProfile: blank update attempted`);
  }
  const result = await AuthApiCall<UserProfile>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile`,
    data: payload,
    headers: { 'rampart-token': getRampartToken()! },
  });
  return result.data;
}

export async function saveProfileMetadata(payload: { welcomeCampaignCompleted?: string }) {
  const result = await AuthApiCall<UserProfile>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile/metadata`,
    data: payload,
    headers: { 'rampart-token': getRampartToken()! },
  });
  return result.data;
}

export async function uploadProfilePic(payload: any) {
  const result = await AuthApiCall<UserProfilePic>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile/pic`,
    data: payload,
    headers: {
      'content-type': 'multipart/form-data',
    },
  });
  return result.data;
}

export async function sendReferrals(payload: ReferralsPostDto) {
  const result = await AuthApiCall<ReferralsResultDto>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/referrals/send`,
    data: payload,
  });
  // eslint-disable-next-line no-console
  return result.data;
}

export async function resendReferralInvite(emailUuid: string) {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/referrals/resend/${emailUuid}`,
  });
  return result.status;
}

export async function getReferralStats() {
  const result = await AuthApiCall<ReferralsStatsDto>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/referrals/stats`,
  });
  return result.data;
}

export async function getActiveReferrals(sortBy: string) {
  const query = {
    orderBy: sortBy,
  };
  const result = await AuthApiCall<ActiveReferralsDto>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/referrals/accepted-invites?${queryObjToString(query)}`,
  });
  return result.data;
}

export async function getArchivedReferrals(sortBy: string) {
  const query = {
    orderBy: sortBy,
  };
  const result = await AuthApiCall<ActiveReferralsDto>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/referrals/archived-invites?${queryObjToString(query)}`,
  });
  return result.data;
}

export async function getPendingInvites() {
  const result = await AuthApiCall<PendingInvitesDto>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/referrals/pending-invites`,
  });
  return result.data;
}

export async function getBlade() {
  const result = await AuthApiCall<BladesDto>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/blades`,
  });
  return result.data;
}

export async function dismissBlade(bladeId: number): Promise<number> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/blades/${bladeId}/dismiss`,
  });
  return result.status;
}

export async function thankReferrerBlade(bladeId: number): Promise<number> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/blades/${bladeId}/thank-referrer`,
  });
  return result.status;
}

export async function saveProfilePic(key: string, payload: any) {
  const result = await AuthApiCall<UserProfilePic>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile/pic/${key}`,
    data: payload,
  });

  return result.data;
}

export async function deleteProfilePic(key: string) {
  const result = await AuthApiCall<UserProfilePic>({
    method: 'DELETE',
    url: `${authApiHost}/api/v1/user/profile/pic/${key}`,
  });
  return result.data;
}

export async function requestEmailVerification({ mode }: { mode?: string } = {}) {
  let url = `${authApiHost}/api/v1/user/request-validation-email`;
  if (mode) {
    url += `?mode=${mode}`;
  }
  const result = await AuthApiCall<{ email: string }>({
    method: 'GET',
    url,
  });
  return result.data.email;
}

export async function getCanChangeInstance() {
  const result = await AuthApiCall<{
    instanceKey: string;
    canChangeInstance: boolean;
    errMessage?: string;
    errCode?: string;
  }>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/can-change-instance`,
  });
  return result.data;
}

interface IPostChangeInstance {
  instanceKey?: string;
  acceptedTermsConditions?: boolean;
  marketingEmailsChecked?: boolean;
  marketingEmailsText?: string;
  marketingSmsChecked?: boolean;
  marketingSmsText?: string;
}

export async function postChangeInstance(data: IPostChangeInstance) {
  const result = await AuthApiCall<LoginResponse>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/change-instance`,
    data,
  });
  return result.data;
}

export interface LocationAnswer {
  country: string;
  postcode: string;
  region: string;
  state: string;
  suburb: string;
}

export interface LocationLookup {
  display_text: string;
  answer_json: LocationAnswer;
}

export async function getLocations(loginResponse: LoginResponse, search?: string) {
  const query = {
    instanceKey: loginResponse.instanceKey,
    search,
    pageSize: search ? 99 : 1000,
  };
  const result = await AuthApiCall<LocationLookup[]>({
    method: 'GET',
    url: `${authApiHost}/api/v1/info/postcode-lookup?${queryObjToString(query)}`,
  });
  return result.data;
}

export async function validateMobileNumber(mobileNumber: string, countryCode?: string) {
  let url = `${authApiHost}/api/v1/mobile/validate?phone=${encodeURIComponent(mobileNumber)}`;
  if (countryCode) {
    url += `&code=${encodeURIComponent(countryCode)}`;
  }
  const result = await AuthApiCall<{ valid: boolean }>({ method: 'GET', url });
  return result.data;
}

export async function requestVerificationSms(countryCode: string, mobileNumber: string) {
  try {
    const result = await AuthApiCall<MobileVerificationResultDto>({
      method: 'GET',
      url: `${authApiHost}/api/v1/user/verification/sms?code=${countryCode}&phone=${mobileNumber}&site=pp`,
    });
    return result.data;
  } catch (err) {
    if (isClientErr(err) && err.data?.code === 'user_already_verified') {
      return {} as MobileVerificationResultDto;
    }
    throw err;
  }
}

export async function mobileReverify(): Promise<any> {
  const result = await AuthApiCall({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/mobile/reverify?site=pp`,
  });
  return result.data;
}

export async function validateAccount(code: string) {
  const result = await AuthApiCall<{
    verificationStatus: 'verified';
    verifiedMobileNumber: string;
  }>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/verification/sms`,
    data: { challenge: code },
  });
  return result.data;
}

export async function changeUsername(username: string): Promise<any> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/change-username`,
    data: { username },
  });
  return result.data;
}

interface ForgotPasswordArgs {
  panelKey: string;
  email: string;
  hostname: string;
}

export async function forgotPassword(args: ForgotPasswordArgs) {
  const result = await AuthApiCall<{ status: string }>({
    method: 'POST',
    url: `${authApiHost}/api/v2/user/forgot-password`,
    data: {
      srcHost: window.location.hostname,
      srcPath: window.location.pathname,
      ...args,
    },
  });
  return result.data;
}

export interface MobileResponse {
  eligibleToRedeem: EligibleToRedeem;
  mobileFreezePeriod: number;
  verifiedMobileNumber: string;
  verifiedMobileNumberAt: string | null;
}

export enum EligibleToRedeem {
  YES = 'yes',
  NOT_VERIFIED = 'not-verified',
  NOT_VERIFIED_MOBILE = 'not-verified-mobile',
  VERIFIED_TOO_RECENTLY = 'verified-too-recently',
  REVERIFY = 'reverify',
  SKIP = 'skip',
}

export async function checkMobile() {
  const result = await AuthApiCall<MobileResponse>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/mobile`,
  });
  return result.data;
}

export async function checkPassword(username: string, password: string): Promise<any> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/check-password`,
    data: {
      platformKey: getConfigPlatformUuid(),
      username,
      password,
    },
  });
  return result.data;
}

export async function changePassword(oldPassword: string, newPassword: string): Promise<any> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/change-password`,
    data: { oldPassword, newPassword },
  });
  return result.data;
}

export interface PaypalInfo {
  paypalEmail: string | null;
}

export async function getPayPalEmail() {
  const result = await AuthApiCall<PaypalInfo>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/paypal-data`,
  });
  return result.data;
}

export interface PaypalSetInfo extends PaypalInfo {
  op: 'created' | 'updated' | 'deleted' | 'noop';
}

export async function postPayPalEmail(paypalEmail: string | null) {
  const result = await AuthApiCall<PaypalSetInfo>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/paypal-data`,
    data: { paypalEmail },
  });
  return result.data;
}

export async function validateSmartfuelCard(payload: any): Promise<any> {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile`,
    data: payload,
  });
  return result.data;
}

export async function getBankData() {
  const result = await AuthApiCall<{ fields: BankData[] }>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/bank-data`,
  });
  return result.data.fields;
}

export async function updateBankData(payload: any) {
  const result = await AuthApiCall<{ fields: BankData[] }>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/bank-data`,
    data: payload,
  });
  return result.data.fields;
}

export interface BankData {
  hint: string;
  isAccountNumber: boolean;
  label: string;
  name: string;
  pattern: string;
  patternErrorMessage: string;
  value: string;
}

export async function closeAccount() {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/close-account`,
    data: { reasonForClosing: 'closed-by-user' },
  });
  const data = result.data as { redirect?: string };
  return data;
}

export async function updateProfileLocation(locationAnswer: LocationAnswer) {
  const result = await AuthApiCall({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile/location`,
    data: locationAnswer,
  });
  return result.data;
}

export async function getPostalAddress() {
  const result = await AuthApiCall<{
    postalAddress: string | null;
  }>({
    method: 'GET',
    url: `${authApiHost}/api/v1/user/profile/postal-address`,
  });
  return result.data;
}

export async function savePostalAddress(payload: { postalAddress: string }) {
  const result = await AuthApiCall<{
    postalAddress: string | null;
  }>({
    method: 'POST',
    url: `${authApiHost}/api/v1/user/profile/postal-address`,
    data: payload,
  });
  return result.data;
}

export interface UnsubscribeAhData {
  dailyEmailFrequencyInDays: number;
  email: string;
  marketingEmailsChecked: boolean;
}

export async function getPublicUnsubscribe(panelUuid: string, accountHolderUuid: string) {
  const result = await AuthApiCall<UnsubscribeAhData>({
    method: 'GET',
    url: `${authApiHost}/api/v1/public/unsubscribe/panel/${panelUuid}/ah/${accountHolderUuid}`,
  });
  return result.data;
}

export interface IPostPublicUnsubscribe extends Partial<UnsubscribeAhData> {
  hash?: string;
  marketingEmailsText?: string;
}

export async function postPublicUnsubscribe(
  panelUuid: string,
  accountHolderUuid: string,
  data: IPostPublicUnsubscribe
) {
  const result = await AuthApiCall<UnsubscribeAhData>({
    method: 'POST',
    url: `${authApiHost}/api/v1/public/unsubscribe/panel/${panelUuid}/ah/${accountHolderUuid}`,
    data,
  });
  return result.data;
}
