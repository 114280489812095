import 'react-toastify/dist/ReactToastify.css';
import './styles/css/index.scss';
import { lazy, Suspense, useCallback, useEffect, useMemo, useState, FC } from 'react';
import { Routes, Route } from 'react-router-dom';
import { toast, ToastContainer, Zoom } from 'react-toastify';
import { ThemeProvider } from '@mui/material/styles';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { I18nextProvider } from 'react-i18next';
import { getTheme } from './styles/theme';
import { retrieveToken, storeToken } from './auth-token';
import ScrollToTop from './routes/ScrollToTop';
import { refreshProfile } from './store/actions/user-profile-actions';
import { logout } from './store/api/auth-api';
import { setPageFaviconUrl, setPageTitle } from './utils/app-load';
import { checkAndUseQueryRedirect } from './utils/uri';
import { getOnboardingData } from './onboarding/onboarding-data';
import { ONBOARDING_CLEAR_DATA } from './store/actions/action-types';
import { afterLogoutRedirect } from './utils/login-logout-uri';
import { useAppDispatch, useAppSelector } from './store';
import { AnyRoute, PrivateRoute, PublicRoute } from './routes/app-route';
import { logError } from './utils/log';
import { UserProfile } from './survey-tool/declarations/auth-api';
import GenericLoader, { GenericLoaderNoI18N } from './survey-tool/components/generic-loader';
import { ReactPageViews } from './react-page-views';
import { useAppNavigate } from './utils/browser-navigate';
import { faviconUrlSelector, panelNameSelector } from './store/selectors/panel-info';
import { gtagSetUserId } from './utils/gtag';
import i18n from './i18n';
import { LanguageChecker } from './language-checker';

const Home = lazy(() => import(/* webpackChunkName: "home" */ './components/home/home'));
const SurveyToolWrapper = lazy(() => import(/* webpackChunkName: "survey-tool-wrapper" */ './survey-tool-wrapper'));
const Unsubscribe = lazy(
  () => import(/* webpackChunkName: "unsubscribe" */ './public-components/unsubscribe/unsubscribe')
);
const StaticPage = lazy(() => import(/* webpackChunkName: "static-page" */ './pages/static-page'));
const Error500 = lazy(() => import(/* webpackChunkName: "error500" */ './components/error500/error500'));
const Onboarding = lazy(() => import(/* webpackChunkName: "onboarding" */ './onboarding/onboarding'));
const Login = lazy(() => import(/* webpackChunkName: "login" */ './public-components/login/login'));
const LoginWidget = lazy(() => import(/* webpackChunkName: "login-widget" */ './public-components/login/login-widget'));
const Signup = lazy(() => import(/* webpackChunkName: "signup" */ './public-components/signup/signup'));
const SignupWidget = lazy(
  () => import(/* webpackChunkName: "signup-widget" */ './public-components/signup/signup-widget')
);
const ForgotPassword = lazy(
  () => import(/* webpackChunkName: "forgot-password" */ './public-components/forgot-password/forgot-password')
);
const AcceptTermsAndConditions = lazy(
  () =>
    import(
      /* webpackChunkName: "accept-terms-and-conditions" */ './public-components/accept-terms-and-conditions/accept-terms-and-conditions'
    )
);
const ValidateEmail = lazy(
  () => import(/* webpackChunkName: "validate-email" */ './public-components/validate-email/validate-email')
);
const CreatePassword = lazy(
  () => import(/* webpackChunkName: "create-password" */ './public-components/create-password/create-password')
);
const MaintenancePage = lazy(
  () => import(/* webpackChunkName: "maintenance" */ './public-components/maintenance/maintenance')
);
const SuspendedPage = lazy(() => import(/* webpackChunkName: "suspended" */ './public-components/suspended/suspended'));
const EmailValidationDone = lazy(
  () =>
    import(
      /* webpackChunkName: "email-validation-done" */ './public-components/email-validation-done/email-validation-done'
    )
);

const Logout: FC = () => {
  useEffect(() => {
    logout()
      .catch(() => {
        /* ignoreErr */
      })
      .then(() => afterLogoutRedirect())
      .catch(() => {
        /* ignoreErr */
      });
  }, []);
  return <GenericLoader />;
};

const App: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useAppNavigate();

  const userProfile = useAppSelector((s) => s.userProfile);
  const panelInfo = useAppSelector((s) => s.panelInfo);
  const theme = useMemo(() => getTheme(panelInfo), [panelInfo]);

  const [loading, setLoading] = useState<boolean>(true);

  const panelName = useAppSelector(panelNameSelector);
  useEffect(() => {
    setPageTitle(panelName);
  }, [panelName]);

  const faviconUrl = useAppSelector(faviconUrlSelector);
  useEffect(() => {
    setPageFaviconUrl(faviconUrl);
  }, [faviconUrl]);

  const onProfileLoaded = useCallback(
    (p: UserProfile) => checkAndUseQueryRedirect(p).catch((err) => logError(err)),
    []
  );

  useEffect(() => {
    if (userProfile?.ppUuid) {
      // google analytics
      window.dataLayer ??= [];
      const userId = userProfile.ppUuid;
      window.dataLayer.push({ userId });
      gtagSetUserId(userId);
    }
  }, [userProfile?.ppUuid]);

  useEffect(() => {
    const ppToken = retrieveToken();
    if (!ppToken) {
      // no ppToken, so no loading happening
      setLoading(false);
      // check if there's any onboarding data stored in the localStorage
      if (window.location.pathname === '/' && getOnboardingData()) {
        navigate('/onboarding');
      }
      return;
    }
    if (ppToken) {
      storeToken(ppToken);
    }
    dispatch(refreshProfile())
      .then(async (up) => {
        // we have successfull profile load, we should clear any potential onboarding info
        if (up.emailValidated) {
          dispatch({ type: ONBOARDING_CLEAR_DATA });
        }
        // we have ppToken, check for value of return for redirection (usually external campaign)
        return onProfileLoaded(up);
      })
      .catch((e) => {
        toast.error(e.message);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dispatch, navigate, onProfileLoaded]);

  // const [userAgentData] = useUserAgentData();
  // (window as any).userAgentData = userAgentData;
  // useEffect(() => {
  //   if (appEnv !== 'production' && !isEmpty(userAgentData)) {
  //     // eslint-disable-next-line no-console
  //     trackBrowserDetails(userAgentData).catch((err) => console.error(err));
  //   }
  // }, [userAgentData]);

  // render
  if (loading) {
    return <GenericLoaderNoI18N />;
  }
  return (
    <Suspense fallback={<GenericLoaderNoI18N />}>
      <I18nextProvider i18n={i18n}>
        <LanguageChecker>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <BrowserStorageWarning />
                <ScrollToTop />
                <ReactPageViews />
                <ToastContainer
                  transition={Zoom}
                  position={toast.POSITION.TOP_CENTER}
                  autoClose={5000}
                  style={{ width: '360px' }}
                  hideProgressBar={false}
                  newestOnTop
                  closeOnClick
                  rtl={false}
                  // ref: https://github.com/fkhadra/react-toastify/commit/3b0d9b103b05bb1fdd98129aa8e751d14dbc28cd
                  // pauseOnVisibilityChange={true}
                  draggable
                  pauseOnHover
                />
                <Routes>
                  <Route path="/login" element={<PublicRoute component={Login} />} />
                  <Route path="/widgets/login" element={<AnyRoute component={LoginWidget} />} />
                  <Route path="/join" element={<PublicRoute component={Signup} />} />
                  <Route path="/widgets/join" element={<AnyRoute component={SignupWidget} />} />
                  <Route
                    path="/accept-terms-and-conditions"
                    element={<PrivateRoute component={AcceptTermsAndConditions} />}
                  />
                  <Route path="/terms" element={<PrivateRoute component={AcceptTermsAndConditions} />} />
                  <Route path="/validate-email" element={<PrivateRoute component={ValidateEmail} />} />
                  <Route path="/reset-password" element={<PublicRoute component={CreatePassword} />} />
                  <Route path="/password-recovery" element={<PublicRoute component={CreatePassword} />} />
                  <Route path="/forgot-password" element={<PublicRoute component={ForgotPassword} />} />
                  <Route path="/horizon-reset-password" element={<PublicRoute component={ForgotPassword} />} />
                  <Route path="/livetribe-reset-password" element={<PublicRoute component={ForgotPassword} />} />
                  <Route path="/perceptive-reset-password" element={<PublicRoute component={ForgotPassword} />} />
                  <Route path="/onboarding/*" element={<PublicRoute component={Onboarding} />} />
                  <Route path="/suspended" element={<PublicRoute component={SuspendedPage} />} />
                  <Route path="/email-validation-done" element={<AnyRoute component={EmailValidationDone} />} />
                  <Route path="/error" element={<AnyRoute component={Error500} />} />
                  <Route path="/logout" element={<AnyRoute component={Logout} />} />
                  <Route path="/static" element={<AnyRoute component={StaticPage} />} />
                  <Route path="/maintenance" element={<AnyRoute component={MaintenancePage} />} />
                  <Route path="/survey/:campaignUuid/*" element={<PrivateRoute component={SurveyToolWrapper} />} />
                  <Route
                    path="/unsubscribe/:panelUuid/:accountHolderUuid/:hash"
                    element={<AnyRoute component={Unsubscribe} />}
                  />
                  <Route path="*" element={<PrivateRoute component={Home} />} />
                </Routes>
              </LocalizationProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </LanguageChecker>
      </I18nextProvider>
    </Suspense>
  );
};

const BrowserStorageWarning: FC = () => null;
// if (isBrowserStorageOk()) {
//   return null;
// }
// return (
//   <div
//     style={{
//       position: 'fixed',
//       zIndex: 9999,
//       top: '0',
//       left: '0',
//       right: '0',
//       textAlign: 'center',
//       backgroundColor: '#e95d5d',
//       color: '#000000',
//     }}
//   >
//     Your browser doesn`t support storage of user data. The functionality of the application will be compromised.
//     Please enable cookies or consider using a different web browser.
//   </div>
// );
export default App;
